import './AboutMe.css';


export const AboutMe = () => {
	return (
		<div className="ms-4 me-4">
			{/* <h3>About Me</h3>
			<div className="card card-more card-opacity">
				<div className="card-body">
					<h5 className="am-card-title">Card 1</h5>
					<p className="card-text">This is a card with 30% opacity.</p>
				</div>
			</div> */}
		</div>
  );
}
